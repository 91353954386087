import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { useTranslate } from "@aan/localization-react";

const boldText = {
  color: "white",
  fontWeight: 600,
  fontFamily: "Montserrat, Roboto, sans-serif"
};
const lightText = {
  color: "white",
  fontWeight: 300,
  fontFamily: "Montserrat, Roboto, sans-serif"
};
const header = {
  color: "white",
  fontWeight: 600,
  fontFamily: "Montserrat, Roboto, sans-serif",
  marginBottom: 8
};

const StoreAddressComponent = ({ addressLine1, city, state, postalCode, width, storeName, orderFlowType, canUseApply }) => {
  const { translate } = useTranslate();

  return (
    <div>
      {width !== "xs" && width !== "sm" && width !== "md" && (
        <div>
          {(orderFlowType === 1 && (!canUseApply || canUseApply !== "No")) &&
            <Typography variant="h5" style={header}>
              {translate("dexists_instore")}
            </Typography>
          }
          {(orderFlowType !== 1 && (!canUseApply || canUseApply !== "No")) &&
            <Typography variant="h5" style={header}>
              {translate("apply_following")}
            </Typography>
          }
          {canUseApply === "No" &&
            <Typography variant="h5" style={header}>
              {translate("sm_contact_store")}
            </Typography>
          }
          <Grid container direction="row">
            <Grid item>
              <i className="icon-map-marker-alt" style={{ fontSize: 26, color: "white", marginRight: 14 }} />
            </Grid>
            <Grid>
              <Typography variant="body1" style={boldText}>
                {translate("sm_location", { store: storeName })}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {addressLine1}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {city}, {state} {postalCode}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}

      {(width === "xs" || width === "sm" || width === "md") && (
        <div>
          {(orderFlowType === 1 && (!canUseApply || canUseApply !== "No")) &&
            <Typography variant="body1" style={header}>
              {translate("dexists_instore")}
            </Typography>
          }
          {(orderFlowType !== 1 && (!canUseApply || canUseApply !== "No")) &&
            <Typography variant="body1" style={header}>
              {translate("apply_following")}
            </Typography>
          }
          {canUseApply === "No" &&
            <Typography variant="body1" style={header}>
              {translate("sm_contact_store")}
            </Typography>
          }
          <Grid container direction="row">
            <Grid item>
              <i className="icon-map-marker-alt" style={{ fontSize: 20, color: "white", marginRight: 14 }} />
            </Grid>
            <Grid>
              <Typography variant="body1" style={boldText}>
                {translate("sm_location", { store: storeName })}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {addressLine1}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {city}, {state} {postalCode}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default StoreAddressComponent;
