/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import * as atlas from "azure-maps-control";
import "../../node_modules/azure-maps-control/dist/atlas.min.css";
import { store } from "../index";
import { setSelectedStore } from '../reducers/storesSlice';


let config = global.config;
let map;
function storeSelected(selectedStore) {
  store.dispatch(setSelectedStore(selectedStore));
}

function pinOver() {
  this.className = "custom-icon-button is-hovered";
}

function pinLeave() {
  this.className = "custom-icon-button";
}

function centerMap(lat, lon) {
  map.setCamera({
    center: [lon, lat],
    zoom: 12,
    duration: 1000,
    type: "fly"
  });
}

function pinClick() {
  storeSelected(this.dataset.store);
  let pinButtons = document.querySelectorAll("button.custom-icon-button");
  pinButtons.forEach(pinButton => {
    pinButton.setAttribute("aria-pressed", false);
  });
  var element = document.getElementById(this.dataset.store);
  element.scrollIntoView({ behavior: "smooth" });
}

function pinSelected(id) {
  var element = document.getElementById(id);
  if (element) {
    element.setAttribute("aria-pressed", true);
  }
  let select = document.querySelectorAll("button.custom-icon-button");
  select.forEach(e => {
    if (e.id === id) {
      e.className = "custom-icon-button is-selected";
      e.removeEventListener("mouseover", pinOver);
      e.removeEventListener("mouseout", pinLeave);
    } else {
      e.className = "custom-icon-button";
      e.addEventListener("mouseover", pinOver, false);
      e.addEventListener("mouseout", pinLeave, false);
    }
  });
}

function removeAllPins() {
  let select = document.querySelectorAll("button.custom-icon-button");
  select.forEach(e => {
    e.parentNode.removeChild(e);
  });
}

function renderMap(lat, long, stores) {
  removeAllPins();
  map = new atlas.Map("aaronsMap", {
    center: [long, lat],
    zoom: 8,
    language: "en-US",
    authOptions: {
      authType: "subscriptionKey",
      subscriptionKey: config.gmapsApiKey
    }
  });

  map.controls.add(new atlas.control.ZoomControl(), {
    position: "bottom-right"
  });

  map.events.add("ready", function() {
    for (let i = 0; i < stores.length; i++) {
      let svgObject = document.getElementById("svg-object").contentDocument;
      if (i === 9) {
        var pintextspan = svgObject.getElementsByClassName("pinTextTspan")[0];
        pintextspan.setAttribute("x", "6");
      }
      let apinO = svgObject.getElementById("aan-pin");
      let clonePin = apinO.cloneNode(true);
      clonePin.id = "svg-aan-pin-" + i;
      let apins = clonePin.outerHTML;
      let svg = apins.replace("{{pintext}}", i + 1);
      let button = document.createElement("button");
      button.className = "custom-icon-button";
      button.id = "aan_pin_" + i;
      button.dataset.store = stores[i].store;
      button.innerHTML = svg;
      button.setAttribute("aria-label", `Select store ${stores[i].name}`);
      button.setAttribute("aria-pressed", false);

      let marker = new atlas.HtmlMarker({
        position: [stores[i].longitude, stores[i].latitude],
        htmlContent: button.outerHTML 
        + "<div style='color: #0033a0; background-color: white; padding: 5px; text-align: center; font-weight: 600'>"
        + stores[i].name + "</div>"
      });

      map.markers.add(marker);

      let mapButton = document.getElementById(`aan_pin_${i}`);
      mapButton.addEventListener("mouseover", pinOver, false);
      mapButton.addEventListener("mouseout", pinLeave, false);
      mapButton.addEventListener("click", pinClick, false);
      if (i === 0) {
        pinSelected("aan_pin_0");
      }
    }

    document
      .getElementById("svg-object")
      .contentDocument.getElementsByClassName("pinTextTspan")[0]
      .setAttributeNS(null, "x", "10");
  });
}
const StoresMap = ({ lat, long, stores, selectedStore, initialRender }) => {
  useEffect(() => {
    if (stores.length > 0) {
      renderMap(lat, long, stores);
    }
  }, [lat, long, stores]);

  useEffect(() => {
    let id = "aan_pin_";
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].store === selectedStore) {
        id = id + i;
        if (!initialRender) {
          centerMap(stores[i].latitude, stores[i].longitude);
        }
        break;
      }
    }
    pinSelected(id);
  }, [selectedStore]);
  return (
    <div
      id="aaronsMap"
      style={{ height: "30vh", minWidth: "95vw", maxWidth: "95vw", margin: "0 auto", marginTop: 5 }}
    ></div>
  );
};

export default StoresMap;
