function trackProgress(path)
{
    let pace = window.Pace;    
    if(!pace.running && path !== "confirm") {
        pace.restart();
    }

    let paceSource = null;
    if (pace.sources.length !== 3)
        throw new Error('Custom Pace Source not found');

    paceSource = pace.sources[2];
    
    let tracker = {
        progress: 0
    };
    paceSource.elements.push(tracker);

    return {
        done: () => {
            paceSource.elements.splice(paceSource.elements.indexOf(tracker), 1);
        }
    };
}

export default trackProgress;