import { Typography } from "@material-ui/core";
import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { setChannelType, startNewSession } from "../reducers/sessionSlice";
import { initializeSession } from "@aan/order-form";

function Welcome() {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    async function startNewSessionAsync() {
      let searchParams = new URLSearchParams(search);
      let sessionId = await dispatch(startNewSession(pathname));
      dispatch(setChannelType(5));
      dispatch(initializeSession(sessionId, 1, 5));
      dispatch(push(`/form/welcome?key=${searchParams.get("key")}`));
    }
    if (pathname && search) {
      startNewSessionAsync();
    }
  }, [dispatch, pathname, search]);
  return (
    <div>
      <Typography variant="h4">Loading...</Typography>
    </div>
  );
}

export default Welcome;
