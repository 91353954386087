import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslate } from "@aan/localization-react";
import { Typography } from "@material-ui/core";
import IconBed from "../../images/ico-bed.png";
import IconCouch from "../../images/ico-couch.png";
import IconLamp from "../../images/ico-lamp.png";
import IconTable from "../../images/ico-table.png";
import IconTv from "../../images/ico-tv.png";
import IconDresser from "../../images/ico-dresser.png";
 
const SplashBulletsMobile = props => {
  const { translate } = useTranslate();

  const headerText={
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: 600,
    fontSize: 18
  }

  const bodyText={
    fontWeight: 500,
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontSize: 16
  }

  const bullet={
    marginTop: 20
  }

  const iconContainerStyle={
    marginTop: 5,
    marginRight: 22
  }

  const iconStyle={
    width: 35
  };

  return (
    <div>
        <Grid container="row" style={bullet}>
        <Grid item xs={1} style={iconContainerStyle}>
            <img
                src={IconTv}
                style={iconStyle}
                alt=""
            />
        </Grid>
        <Grid item xs={10}>
            <Typography style={headerText}>
                {translate("apply_high_approvals")}
            </Typography>
            <Typography style={bodyText}>
                {translate("apply_no_perfect_credit")}
            </Typography>
        </Grid>
      </Grid>
      <Grid container="row" style={bullet}>
        <Grid item xs={1} style={iconContainerStyle}>
            <img
                src={IconTable}
                style={iconStyle}
                alt=""
            />
        </Grid>
        <Grid item xs={10}>
            <Typography style={headerText}>
                {translate("apply_choose_plan")}
            </Typography>
            <Typography style={bodyText}>
                {translate("apply_lease_terms")}
            </Typography>
        </Grid>
      </Grid>
      <Grid container="row" style={bullet}>
        <Grid item xs={1} style={iconContainerStyle}>
            <img
                src={IconBed}
                style={iconStyle}
                alt=""
            />
        </Grid>
        <Grid item xs={10}>
            <Typography style={headerText}>
                {translate("apply_product_love")}
            </Typography>
            <Typography style={bodyText}>
                {translate("apply_shop_products")}
            </Typography>
        </Grid>
      </Grid>
        <Grid container="row" style={bullet}>
        <Grid item xs={1} style={iconContainerStyle}>
            <img
                src={IconCouch}
                style={iconStyle}
                alt=""
            />
        </Grid>
        <Grid item xs={10}>
            <Typography style={headerText}>
                {translate("apply_schedule_delivery")}
            </Typography>
            <Typography style={bodyText}>
                {translate("apply_free_delivery")}
            </Typography>
        </Grid>
      </Grid>
      <Grid container="row" style={bullet}>
        <Grid item xs={1} style={iconContainerStyle}>
            <img
                src={IconLamp}
                style={iconStyle}
                alt=""
            />
        </Grid>
        <Grid item xs={10}>
            <Typography style={headerText}>
                {translate("apply_pay_your_way")}
            </Typography>
            <Typography style={bodyText}>
                {translate("apply_optional_ezpay")}
            </Typography>
        </Grid>
      </Grid>
      <Grid container="row" style={bullet}>
        <Grid item xs={1} style={iconContainerStyle}>
            <img
                src={IconDresser}
                style={iconStyle}
                alt=""
            />
        </Grid>
        <Grid item xs={10}>
            <Typography style={headerText}>
                {translate("apply_left_off")}
            </Typography>
            <Typography style={bodyText}>
                {translate("apply_return")}
            </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SplashBulletsMobile;
