import React from "react";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  merchandiseIconsContainerDesktop: {
    width: 300,
    marginLeft: -22,
    marginBottom: 30
  },  
  merchandiseIconsContainerPhone: {
    width: 300,
    marginLeft: -22,
    marginBottom: 10
  },  
  merchandiseIconDesktop: {
    fontSize: 60,
    color: "white",
  },  
  merchandiseIconPhone: {
    fontSize: 40,
    color: "white"
  }
}));

const FurnitureIcons = (props) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={(props.width === 'xs' || props.width === 'sm') ? classes.merchandiseIconsContainerPhone : classes.merchandiseIconsContainerDesktop}>
      <i className={classNames("icon-ico-washer", (props.width === 'xs' || props.width === 'sm') ? classes.merchandiseIconPhone : classes.merchandiseIconDesktop)}/>
      <i className={classNames("icon-ico-couch", (props.width === 'xs' || props.width === 'sm') ? classes.merchandiseIconPhone : classes.merchandiseIconDesktop)}/>
      <i className={classNames("icon-ico-computer", (props.width === 'xs' || props.width === 'sm') ? classes.merchandiseIconPhone : classes.merchandiseIconDesktop)}/>
    </Grid>
  );
};

export default FurnitureIcons;