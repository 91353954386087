import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApplyApi from "../api/applyApi";

export const startNewSession = createAsyncThunk(
  "startNewSession",
  async (route, { getState, dispatch }) => {
    const { initialUrl, referrer } = getState().session;
    let response = await ApplyApi.postSession(initialUrl, referrer, route);
    return response.id;
  }
);

const initialState = {
  initialUrl: window.location.href,
  referrer: document.referrer,
  channelTypeId: 2,
  alert: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialState,
  reducers: {
    setOrderFlowType: (state, action) => {
      state.orderFlowType = action.payload.orderFlowType;
    },
    setChannelType: (state, action) => {
      state.channelTypeId = action.payload.channelTypeId;
    },
    showCancelModal: (state, action) => {
      state.cancelModalIsVisible = action.payload;
    },
    clearSession: (state, action) => {
      return initialState;
    },
    showAlert: (state, action) => {
      state.alert = {
        alertType: action.payload.alertType,
        alertMessage: action.payload.alertMessage,
      };
    },
    hideAlert: (state) => {
      state.alert = null;
    },
  },
  extraReducers: {
    [startNewSession.fulfilled]: (state, action) => {
      state.isSessionActive = true;
      state.sessionId = action.payload;
      state.loading = "idle";
    },
    [startNewSession.pending]: (state) => {
      state.loading = "pending";
    },
    [startNewSession.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    },
  },
});

const { actions, reducer } = sessionSlice;
export const setOrderFlowType = (orderFlowType) =>
  actions.setOrderFlowType({ orderFlowType });
export const setChannelType = (channelTypeId) =>
  actions.setChannelType({ channelTypeId });
export const showCancelModal = (cancelModalIsVisible) =>
  actions.showCancelModal({ cancelModalIsVisible });
export const clearSession = () => actions.clearSession();
export const showAlert = (alertType, alertMessage) =>
  actions.showAlert({ alertType, alertMessage });
export const hideAlert = () => actions.hideAlert();
export default reducer;
