/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { loadStore } from "../reducers/storeSlice";
import {
  getStoresByPostalCode,
  setSelectedStore,
} from "../reducers/storesSlice";
import { useTranslate } from "@aan/localization-react";
import StoresMap from "../components/StoresMap";
import Search from "@material-ui/icons/Search";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import pin from "../images/pin.svg";
import { Header } from "@aan/order-form";
import { showAlert } from "../reducers/sessionSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import NoLeasingPowerInfoModal from "../components/Splash/NoLeasingPowerInfoModal";
import ApplyApi from '../api/applyApi';
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const useStyles = makeStyles((theme) => ({
  locationSelected: {
    backgroundColor: theme.palette.primary.light,
    padding: 0,
    "&:focus": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  locationNotSelected: {
    padding: 0,
  },
  searchIcon: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    color: "white",
    borderRadius: 5,
    height: 52,
  },
  continueButton: {
    width: 330,
    height: 50,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

function StoreSelectorNew() {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [postalCode, setPostalCode] = useState("");
  const [searchedPostalCode, setSearchedPostalCode] = useState("");
  const [firstLocationEntered, setFirstLocationEntered] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState("");
  const [isNoLeasingPowerInfoModalOpen, setIsNoLeasingPowerInfoModalOpen] = useState("");
  const stores = useSelector((state) => state.stores.stores);
  const postalCodeLat = useSelector((state) => state.stores.lat);
  const postalCodeLong = useSelector((state) => state.stores.long);
  const selectedStore = useSelector((state) => state.stores.selectedStore);
  const initialRender = useSelector((state) => state.stores.initialRender);

  function handlePostalCodeChanged(e) {
    setPostalCode(e.target.value);
  }

  function handleStoreSelection(store) {
    dispatch(setSelectedStore(store));
  }

  function storeSearch(e) {
    if (!/\b\d{5}\b/g.test(postalCode)) {
      setPostalCodeError(translate("ss_invalid_postalCode"));
    } else {
      setShowLoadingSpinner(true);
      setPostalCodeError("");
      dispatch(getStoresByPostalCode(postalCode))
        .then(() => {
          setFirstLocationEntered(true);
          setSearchedPostalCode(postalCode);
          setShowLoadingSpinner(false);
        })
        .catch((err) => {
          setShowLoadingSpinner(false);
          setPostalCodeError(translate("ss_failure_storeList"));
        });
    }
    e.preventDefault();
  }

  async function continueOn() {
    let store = await ApplyApi.getStore(selectedStore);
    dispatch(loadStore(selectedStore))
      .then(unwrapResult)
      .then(() => {
        if (store?.defaultOrderFlowType !== 1){
          setIsNoLeasingPowerInfoModalOpen(true);
        } else {
          dispatch(push("/form/personal"));
        }
      })
      .catch(() => {
        dispatch(
          showAlert("error", "Oops, something went wrong. Please try again.")
        );
      });
  }

  return (
    <div style={{ width: "100vw" }}>
      <Header />
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ width: "100vw" }}
      >
        {showLoadingSpinner && (
          <CircularProgress style={{ position: "fixed", top: "50%" }} />
        )}
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ width: "100vw" }}
      >
        {firstLocationEntered === false && (
          <Grid item>
            <div style={{ padding: 10, textAlign: "left" }}>
              <Typography
                variant="h4"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                  color: "#474747",
                }}
              >
                {translate("ss_header")}
              </Typography>
              <Typography variant="h6" style={{ fontWeight: 100 }}>
                {translate("ss_instruction")}
              </Typography>
            </div>
          </Grid>
        )}
        {stores.length > 0 &&
          postalCodeLat !== "" &&
          postalCodeLong !== "" &&
          firstLocationEntered === true &&
          !isIE11 && (
            <Grid item>
              <StoresMap
                stores={stores}
                lat={postalCodeLat}
                long={postalCodeLong}
                selectedStore={selectedStore}
                initialRender={initialRender}
              />
            </Grid>
          )}
        <Grid item>
          <form onSubmit={storeSearch}>
            <Grid container direction="row" style={{ marginTop: 5 }}>
              <Grid item>
                <TextField
                  id="postalCode"
                  label={translate("ss_postalCode")}
                  value={postalCode}
                  onChange={handlePostalCodeChanged}
                  margin="normal"
                  variant="outlined"
                  style={{ width: 240 }}
                  autoFocus={true}
                />
                {postalCodeError && (
                  <FormHelperText error={true}>
                    {postalCodeError}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item style={{ marginLeft: 8, marginTop: 17 }}>
                <IconButton
                  variant="contained"
                  className={classes.searchIcon}
                  type="submit"
                  aria-label="Submit search"
                  id="submit-postalcode-button"
                >
                  <Search style={{ fontSize: 30 }} />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid container direction="column" alignContent="center">
        <Grid item>
          <div style={{ overflow: "auto", marginBottom: 20, marginTop: 10 }}>
            {stores.length <= 0 && firstLocationEntered === true && (
              <Grid
                container
                direction="row"
                style={{ padding: 10, margin: "0 auto", marginTop: 20 }}
              >
                <Grid item xs={3}>
                  <ErrorOutlineIcon
                    style={{ fontSize: 75, color: "#00369d" }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography style={{ fontSize: 18 }}>
                    {`${translate("ss_noStore")} `}
                    <b>{searchedPostalCode}</b>.
                  </Typography>
                  <Typography style={{ marginTop: 10, fontSize: 18 }}>
                    {translate("ss_modifySearch")}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {stores.length > 0 && firstLocationEntered === true && (
              <div
                style={{
                  width: "95%",
                  minWidth: 300,
                  maxHeight: "25vh",
                  margin: "0 auto",
                  marginBottom: 30,
                }}
              >
                {stores.map((store) => (
                  <div key={`listitem-${store.store}`}>
                    <ListItem
                      button
                      onClick={() => handleStoreSelection(store.store)}
                      className={
                        store.store === selectedStore
                          ? classes.locationSelected
                          : classes.locationNotSelected
                      }
                      autoFocus={store.store === selectedStore ? true : false}
                      aria-pressed={
                        store.store === selectedStore ? true : false
                      }
                      id={`${store.store}-button`}
                    >
                      <Grid container direction="row" id={store.store}>
                        <Grid
                          item
                          xs={2}
                          style={{ marginTop: 1, marginBottom: 1 }}
                        >
                          <div
                            style={{
                              backgroundColor: "#d3d5db",
                              width: 36,
                              height: "100%",
                              color: "#474747",
                              fontWeight: "bold",
                              padding: 0,
                            }}
                          >
                            <Typography
                              style={{ paddingLeft: 8, paddingTop: 8 }}
                            >
                              {stores.indexOf(store) + 1}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          style={{ marginTop: 5, marginBottom: 5 }}
                        >
                          <ListItemText
                            primary={`${store.name}`}
                            secondary={`${store.addressLine1}`}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <div
                      style={{
                        height: 2,
                        width: "100%",
                        backgroundColor: "#d3d5db",
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Grid>
        {firstLocationEntered === true && stores.length > 0 && (
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                style={{
                  position: "fixed",
                  bottom: 15,
                  marginRight: "auto",
                  marginLeft: "auto",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                }}
              >
                <Button
                  variant="contained"
                  onClick={continueOn}
                  color="primary"
                  id="continue-button"
                  disabled={selectedStore === ""}
                  className={classes.continueButton}
                >
                  {translate("continue")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <object
        id="svg-object"
        data={pin}
        type="image/svg+xml"
        style={{ visibility: "hidden" }}
      ></object>
      {isNoLeasingPowerInfoModalOpen && <NoLeasingPowerInfoModal open={isNoLeasingPowerInfoModalOpen} onClose={() => setIsNoLeasingPowerInfoModalOpen(false)}/>}
    </div>
  );
}

export default StoreSelectorNew;
