import React from "react";
import Grid from '@material-ui/core/Grid';
import StoreAddressComponent from '../components/StoreAddressComponent';
import StorePhoneComponent from './StorePhoneComponent';

const ContactCopy = (props) => {
  let { addressLine1, city, state, postalCode, phoneNumber, width, storeName, orderFlowType, canUseApply } = props;
  return (
    <Grid container direction="column">
      <Grid item style={{ marginBottom: 30 }}>
        <StoreAddressComponent city={city} state={state} postalCode={postalCode} addressLine1={addressLine1} width={width} storeName={storeName} orderFlowType={orderFlowType} canUseApply={canUseApply}/>
      </Grid>
      <Grid item>
        <StorePhoneComponent phoneNumber={phoneNumber} width={width}/>
      </Grid>
    </Grid>
  );
}; 

export default ContactCopy;