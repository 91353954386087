import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Page } from "./Page";
import { OrderFormApp } from "@aan/order-form";
import ApplySplash from "./containers/ApplySplash";
import Welcome from "./containers/Welcome";
import StoreSelectorNew from "./containers/StoreSelectorNew";
import Sweepstakes from "./containers/Sweepstakes";
import "./fontello/css/fontello.css";
import { initializeWithAllTranslations } from "@aan/localization-react";
import { useTranslate } from "@aan/localization-react";
import { aanTheme } from "@aan/material-ui";
import { IconButton, Snackbar } from "@material-ui/core";
import { hideAlert } from "./reducers/sessionSlice";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import SplashNew from "./containers/SplashNew";

let config = global.config;

function App() {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const sessionId = useSelector((state) => state.session.sessionId);
  const alertType = useSelector((state) => state.session.alert?.alertType);
  const alertMessage = useSelector(
    (state) => state.session.alert?.alertMessage
  );
  const location = useLocation();
  useEffect(() => {
    async function initializeLocalization() {
      await dispatch(
        initializeWithAllTranslations({
          appId: config.localizationAppId,
          defaultLanguage: "en",
          renderInnerHtml: true,
          isQa: config.env !== "prod",
        })
      );
    }
    initializeLocalization();
  }, [dispatch]);

  function closeAlert() {
    dispatch(hideAlert());
  }

  return (
    <MuiThemeProvider theme={aanTheme}>
      <div>
        <Snackbar
          open={!!alertType && !!alertMessage}
          autoHideDuration={60000}
          onClose={closeAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            severity={alertType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={closeAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <Switch>
          <Route
            path="/706f746f676f6c64"
            render={() => <Sweepstakes />}
          />
          <Route
            path="/form"
            render={() =>
              !sessionId && location.pathname !== "/form/welcome" ? (
                <Redirect to="/" />
              ) : (
                <OrderFormApp exitPath="/" />
              )
            }
          />
          <Page
            exact
            path="/welcome"
            title={`${translate("apply_page_title")} - ${translate(
              "base_page_title"
            )}`}
            render={() => <Welcome />}
          />
          <Page
            exact
            path="/apply"
            title={`${translate("apply_page_title")} - ${translate(
              "base_page_title"
            )}`}
            render={() => <ApplySplash />}
          />
          <Page
            exact
            path="/storeSelector"
            render={() => <StoreSelectorNew />}
            title={`${translate("ss_instruction")} - ${translate(
              "base_page_title"
            )}`}
          />
          <Page
            exact
            path="/"
            render={() => <SplashNew />}
            title={`${translate("splash_page_title")} - ${translate(
              "splash_page_getstarted"
            )}`}
          />
          <Page
            path="/:storeNumber"
            render={() => <SplashNew />}
            title={`${translate("splash_page_title")} - ${translate(
              "splash_page_getstarted"
            )}`}
          />
        </Switch>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
