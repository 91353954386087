import React, {  } from "react";
import useWidth from "../hooks/useWidth";
import AaronsLogo from "../components/Splash/AaronsLogo";
import LanguagePicker from "../components/LanguagePicker";
import SplashTitle from "../components/Splash/SplashTitle";
import { Box, Grid, Typography, Button } from "@material-ui/core";
//import ContinueButton from "../components/ContinueButton";
import PrivacyAndTosFooter from "../components/PrivacyAndTosFooter";
import SplashBulletsMobile from "../components/Splash/SplashBulletsMobile";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import { useTranslate } from "@aan/localization-react";
import Blades from "../images/blades.png";
import SlashBkgnd from "../images/slash-bkgrnd.png";
import GradientHorizontal from "../images/gradient-horizontal.svg";
import PatternedBackground from "../images/icon-background-pattern-small.png"
import PatternedBackgroundShort from "../images/icon-background-pattern-small-alt.png"
import ConfettiBackgroundBlue from "../images/confeti-background-pattern.png"
import ConfettiBackground from "../images/Confetti_patterns_opacity.png"
import { AARONS_BLUE } from "../constants/colors";

const textBoldStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center"
};

const textBlueStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    textAlign: "center",
    fontWeight: "bold"
};

const textBlueTitleStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    fontWeight: "bold",
    fontSize: 20
};

const textBlueAltStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 20
};

const numListTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    fontWeight: 600,
    fontSize: 18
};

const numFontStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontSize: 22,
    color: "white",
    fontWeight: "bold",
    marginTop: -5,
    marginLeft: 5
};

const numListItemStyle = {
    marginTop: 10
};

const circledNumberStyle = {
    border: `thin ${AARONS_BLUE} solid`, backgroundColor: AARONS_BLUE, padding: 10, borderRadius: 50, width: 20, height: 20
};

const bulletSectionHeaderTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: 10
}

const buttonTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    color: AARONS_BLUE,
    fontSize: 20,
    marginRight: 10
}

const buttonTextAltStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    color: "white",
    fontSize: 18,
    marginRight: 10
}

const legalTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: "white",
}

function SplashNewMobile({store, handleApplyNowClick, canGetDecision, setIsWhatIsRentToOwnModalOpen}) {
  const width = useWidth();
  const { translate } = useTranslate();

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Box style={{ backgroundImage: `url(${PatternedBackground})`, backgroundAttachment: "fixed" }}>
        <Box style={{ paddingTop: 10 }}>
            <Box style={{ marginLeft: 15 }}>
                <AaronsLogo width={width} />
            </Box>
            <LanguagePicker
                style={{ position: "absolute", right: 10, top: 10, color: "white" }}
            />
            <Box style={{ backgroundImage: `url(${GradientHorizontal})`, backgroundRepeat: "repeat-y" }}>
                <Box style={{ padding: 20 }}>
                    <SplashTitle
                        width={width}
                        page="mainSplash"
                        store={store.storeNumber}
                        canGetDecision={canGetDecision}
                    />
                </Box>
            </Box>
            <Box style={{ padding: 30 }}>
                {canGetDecision && <Typography style={{
                    fontFamily: "Montserrat, Roboto, sans-serif",
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: 5,
                    fontSize: 16
                }}>
                    {translate("apply_what_is_leasing")}
                </Typography>}
                {canGetDecision && <Typography style={{
                    fontFamily: "Montserrat, Roboto, sans-serif",
                    color: "white",
                    fontSize: 16
                }}>
                    {translate("apply_leasing_desc")}
                </Typography>}
                {!canGetDecision && 
                        <Box>
                            <Typography style={{
                                fontFamily: "Montserrat, Roboto, sans-serif",
                                color: "white",
                                fontSize: 16,
                                marginBottom: 20
                            }}>
                                {translate("apply_para1")}
                            </Typography>
                            <Typography style={{
                                fontFamily: "Montserrat, Roboto, sans-serif",
                                color: "white",
                                fontSize: 16
                            }}>
                                {translate("apply_para2")}
                            </Typography>
                        </Box>
                    }
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                        <Box style={{ border: "thin #CCEEFB solid", padding: 20, backgroundImage: `url(${ConfettiBackgroundBlue})`, marginTop: 16}}>
                            <Typography style={textBlueTitleStyle}>{translate("apply_ready_to_apply")}</Typography>
                            <Typography style={textBlueAltStyle}>{translate("apply_just_follow")}</Typography>
                            <Grid container direction="row" style={numListItemStyle}>
                                <Grid item xs={2} style={{ marginRight: 10 }}>
                                    <Box style={circledNumberStyle}>
                                        <Typography style={numFontStyle}>1</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography style={numListTextStyle}>{translate("apply_fill_out_app")}</Typography>
                                </Grid>
                            </Grid>
                            {canGetDecision && <Grid container direction="row" style={numListItemStyle}>
                                <Grid item xs={2} style={{ marginRight: 10 }}>
                                    <Box style={circledNumberStyle}>
                                        <Typography style={numFontStyle}>2</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography style={numListTextStyle}>{translate("apply_discover_approved")}</Typography>
                                </Grid>
                            </Grid>}
                            <Grid container direction="row" style={numListItemStyle}>
                                <Grid item xs={2} style={{ marginRight: 10 }}>
                                    <Box style={circledNumberStyle}>
                                        {!canGetDecision && <Typography style={numFontStyle}>2</Typography>}
                                        {canGetDecision && <Typography style={numFontStyle}>3</Typography>}
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography style={numListTextStyle}>{translate("apply_shop_favorite")}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item style={{ marginTop: 30 }}>
                        <Button variant="contained" onClick={handleApplyNowClick} style={{ width: 350, height: 80, backgroundColor: "white" }}>
                            <Typography style={buttonTextStyle}>{translate("home_apply_now")}</Typography>
                            <ForwardArrowIcon style={{ fontSize: 34, color: AARONS_BLUE }} />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
      </Box>    
      <Box style={{ backgroundColor: "white", backgroundImage: `url(${ConfettiBackground})`, backgroundSize: "200%", padding: 30 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                <Typography style={bulletSectionHeaderTextStyle}>{translate("apply_flexible")}</Typography>
            </Grid>
            <Grid item style={{ maxWidth: 600 }}>
                <SplashBulletsMobile/>
            </Grid>
        </Grid>
      </Box>
      <Box style={{ backgroundColor: AARONS_BLUE, padding: 30, backgroundImage:  `url(${PatternedBackgroundShort})`, backgroundAttachment: "fixed" }}>
        <Grid container style={{ border: "thin #CCEEFB solid", padding: 20, backgroundImage: `url(${SlashBkgnd})`, backgroundSize: "cover" }} direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                <img
                    src={Blades}
                    style={{ width: 160, marginLeft: -50 }}
                    alt=""
                />
            </Grid>
            <Grid item>
                <Typography style={textBlueStyle}>{translate("apply_affordable")}</Typography>
                <Typography style={textBoldStyle}>{translate("apply_learn_more")}</Typography>
            </Grid>
            <Grid item>
                <Button onClick={() => setIsWhatIsRentToOwnModalOpen(true)} variant="contained" color="primary" style={{ width: 260, height: 50, marginTop: 40 }}>
                    <Typography style={buttonTextAltStyle}>
                        {translate("apply_see_how_works")}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
        </Box>
        <Box style={{ backgroundColor: AARONS_BLUE, padding: 30 }}>
        {store?.storeNumber ? (
          <div>
            <Typography style={legalTextStyle}>
              {store.defaultOrderFlowType === 1
                ? translate("home_footer_3")
                : translate("home_footer_2")}
            </Typography>
          </div>
        ) : (
          <div>
            <Typography style={legalTextStyle}>
              {translate("home_footer")}
            </Typography>
          </div>
        )}
        <Box style={{ marginTop: 20 }}>
            <PrivacyAndTosFooter />
        </Box>
        </Box>
    </Box>
  );
}

export default SplashNewMobile;
