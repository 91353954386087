import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";

let config = global.config;

const styles = () => ({
  button: {
    background: 'white',
    fontWeight:500,
    color: 'rgb(0, 172, 236)',
    borderRadius: 4,
    borderColor: 'rgb(0, 172, 236)',
    borderWidth: 1,
    borderStyle: 'solid',
    width: 117,
    height: 58,
    fontSize: 24,
    '&:hover': {
       background: 'rgb(155, 212, 223)'
    }
  }
});

const appBar = {
  position: "relative"
};

const TermsOfServiceModal = (props) => {
  const languages = useSelector((state) => state.localize.languages);
  let language = languages.find((element) => element.active === true);
  return (
    <Dialog fullScreen={true} open={props.isOpen}>
      <AppBar style={appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={props.onCloseClick} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <iframe 
        src={
          language && language.code === "es"
            ? config.termsOfServiceUrlSpanish
            : config.termsOfServiceUrl
        } 
        width="100%" height="100%" title="wiki">
      </iframe>
    </Dialog>
  );
};

TermsOfServiceModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired
};

export default withStyles(styles)(TermsOfServiceModal);

