import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslate } from "@aan/localization-react";
import { push } from "connected-react-router";

const boldText = {
  color: "#333333",
  fontWeight: 600,
  fontSize: 12,
  fontFamily: "Montserrat, Roboto, sans-serif"
};
const lightText = {
  color: "#333333",
  fontWeight: 300,
  fontSize: 12,
  fontFamily: "Montserrat, Roboto, sans-serif"
};

function NoLeasingPowerInfoModal({ onClose, open }) {  
    const { translate } = useTranslate();
    const dispatch = useDispatch();
    const store = useSelector((state) => state.store);
    async function continueOn() {
        dispatch(push("/form/personal"));
    }

    return (
      <Dialog open={open} fullWidth={true} maxWidth="xs">
        <div style={{ position: "relative" }}>
          <div>
            <div style={{ backgroundColor: "#0069c8", height: 20 }} />
          </div>
          <div style={{ textAlign: "center", marginBottom: 22, paddingLeft: 30, paddingRight: 30 }}>
            <span>
              <i className="icon-store" style={{ fontSize: 80, marginBottom: -10, color: "#0069c8" }} />
            </span>
            <br />
            <span>
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#404040",
                  fontFamily: "Montserrat, Roboto, sans-serif",
                  fontSize: 16,
                  marginBottom: 20
                }}
              >
                {translate("get_started_nd")}
              </Typography>
              <Typography style={{textAlign: "left", fontSize: 14}}>
                {translate("apply_para1")}
              </Typography>
              <Typography style={{textAlign: "left", fontSize: 14, marginTop: 18}}>
                  {translate("apply_para2")}
              </Typography>
            </span>
          </div>
          <div style={{ margin: "0 auto", paddingLeft: 5, paddingRight: 5 }}>
            <Grid container direction="row">
              <Grid item xs={2}>
                <i className="icon-map-marker-alt" style={{ fontSize: 28, color: "#0069c8" }} />
              </Grid>
              <Grid item xs={10}>
                <Typography style={boldText} data-cy="your-store-location">
                  {`${translate("sm_location", {store: store.storeName})}`}
                </Typography>
                <Typography variant="body1" style={lightText}>
                  {store.addressLine1}
                </Typography>
                <Typography variant="body1" style={lightText}>
                  {store.city}, {store.state} {store.postalCode}
                </Typography>
              </Grid>
            </Grid>
            <a href={"tel:" + store.phoneNumber} style={{ textDecoration: "none", color: "#333333" }}>
              <Grid container direction="row" style={{ marginTop: 16 }}>
                <Grid item xs={2}>
                  <i className="icon-phone-alt" style={{ fontSize: 28, color: "#0069c8" }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" style={boldText}>
                    {translate("sm_contact")}
                  </Typography>
                  <Typography variant="body1" style={lightText}>
                    {store.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
            </a>
          </div>
          <Typography variant="body2" style={{ fontSize: 10, padding: 26 }}>
              {translate("apply_legal_2")}
          </Typography>
          <DialogActions style={{position: "sticky", bottom: 0, paddingBottom: 20, width: "90%", backgroundColor: "white" }}>
            <Button
              onClick={onClose}
              color="primary"
              data-cy="store-confirm-no"
            >
              <Typography style={{ fontSize: 14 }}>
                {translate("find_another_store")}
              </Typography>
            </Button>
            <Button
              variant="contained"
              onClick={continueOn}
              color="primary"
              data-cy="store-confirm-yes"
            >
              {translate("continue")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );  
}

export default NoLeasingPowerInfoModal;
