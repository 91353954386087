import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from "connected-react-router";
import { history } from "./reducers";
import configureAppStore from "./store/configureStore";
import { LocalizeProvider } from "react-localize-redux";

const localState = sessionStorage
  ? sessionStorage.getItem("APPLY_WEB_APP")
    ? JSON.parse(sessionStorage.getItem("APPLY_WEB_APP"))
    : {}
  : {};

if (window.location.pathname !== "" && window.location.pathname !== "/") {
  var link = document.createElement("meta");
  link.setAttribute("name", "robots");
  link.content = "noindex, nofollow";
  document.getElementsByTagName("head")[0].appendChild(link);
}
export const store = configureAppStore(localState);

if (sessionStorage) {
  store.subscribe(() => {
    sessionStorage.setItem("APPLY_WEB_APP", JSON.stringify(store.getState()));
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LocalizeProvider store={store}>
        <App />
      </LocalizeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

serviceWorker.unregister();
