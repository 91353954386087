import React from "react";
import Heading1 from "../../constants/fonts/Heading1";
import Heading2 from "../../constants/fonts/Heading2";
import { useTranslate } from "@aan/localization-react";
 
const SplashTitle = props => {
  const { translate } = useTranslate();

  return (
    <div>
      {(props.page === "mainSplash" && !props.store && (props.width !== "xs" && props.width !== "sm")) && (
        <Heading1 text={translate("discover_your_lp")}/>
      )}
      {(props.page === "mainSplash" && !props.store && (props.width === "xs" || props.width === "sm")) && (
        <Heading2 text={translate("discover_your_lp")}/>
      )}
      {(props.store && !props.canGetDecision && (props.width !== "xs" && props.width !== "sm")) && (
        <Heading1 text={translate("get_started_nd")}/>
      )}
      {(props.store && !props.canGetDecision && (props.width === "xs" || props.width === "sm")) && (
        <Heading2 text={translate("get_started_nd")}/>
      )}
      {(props.store && props.canGetDecision && (props.width !== "xs" && props.width !== "sm")) && (
        <div>
        <Heading1 text={
        <span>{translate("discover_your_lp")}</span>
        }/>
      </div>
      )}
    {(props.store && props.canGetDecision && (props.width === "xs" || props.width === "sm")) && (
        <Heading2 text={
          <span>{translate("discover_your_lp")}</span>
        }/>
      )}
    </div>
  );
};

export default SplashTitle;
