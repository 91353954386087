class ValidationError extends Error {
    constructor(message, body) {
      super(message);
      this.name = "ValidationError";
      this.message = message;
      this.body = body;
    }
  }
  
  class ServerError extends Error {
    constructor(message) {
      super(message);
      this.name = "ServerError";
      this.message = message;
    }
  }
  
  class NotSuccessError extends Error {
    constructor(message) {
      super(message);
      this.name = "NotSuccessError";
      this.message = message;
    }
  }
  
  export { ValidationError, ServerError, NotSuccessError };
  