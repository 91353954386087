import React from "react";
import { setActiveLanguage } from "react-localize-redux";
import { useDispatch } from "react-redux";
import { LanguagePicker as BaseLanguagePicker } from "@aan/material-ui";

function LanguagePicker({ style }) {
  const dispatch = useDispatch();
  function changeLanguage(langCode) {
    dispatch(setActiveLanguage(langCode));
  }
  return (
    <div style={style}>
      <BaseLanguagePicker onLanguageSelected={changeLanguage} />      
    </div>
  );
}

export default LanguagePicker;
