import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { useTranslate } from "@aan/localization-react";

const boldText = {
  color: "white",
  fontWeight: 600,
  fontFamily: "Montserrat, Roboto, sans-serif"
};
const lightText = {
  color: "white",
  fontWeight: 300,
  fontFamily: "Montserrat, Roboto, sans-serif"
};

const StorePhoneComponent = ({ phoneNumber, width }) => {
  const { translate } = useTranslate();

  return (
    <div>
      {width !== "xs" && width !== "sm" && width !== "md" && (
        <a href={"tel:" + phoneNumber} style={{ textDecoration: "none", color: "white" }}>
          <Grid container direction="row">
            <Grid item>
              <i className="icon-phone-alt" style={{ fontSize: 26, color: "white", marginRight: 14 }} />
            </Grid>
            <Grid item>
              <Typography variant="h5" style={boldText}>
                {translate("apply_call")}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        </a>
      )}

      {(width === "xs" || width === "sm" || width === "md") && (
        <a href={"tel:" + phoneNumber} style={{ textDecoration: "none", color: "white" }}>
          <Grid container direction="row">
            <Grid item>
              <i className="icon-phone-alt" style={{ fontSize: 20, color: "white", marginRight: 14 }} />
            </Grid>
            <Grid item>
              <Typography variant="body1" style={boldText}>
                {translate("apply_call")}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {phoneNumber}
              </Typography>
            </Grid>
          </Grid>
        </a>
      )}
    </div>
  );
};

export default StorePhoneComponent;
