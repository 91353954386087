import { connectRouter } from "connected-react-router";
import storesReducer from '../reducers/storesSlice';
import storeReducer from '../reducers/storeSlice';
import sessionReducer from '../reducers/sessionSlice';
import { localizeReducer } from 'react-localize-redux';
import { orderFormReducer } from '@aan/order-form';
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const rootReducer = {
    router: connectRouter(history),
    stores: storesReducer,
    store: storeReducer,
    session: sessionReducer,
    localize: localizeReducer,
    orderForm: orderFormReducer
};

export default rootReducer;
