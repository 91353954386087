import React, { useState, useEffect } from "react";
import useWidth from "../hooks/useWidth";
import { Box } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { loadStore } from "../reducers/storeSlice";
import {
  setChannelType,
  startNewSession,
  clearSession,
  showAlert,
} from "../reducers/sessionSlice";

import StoreConfirmationModalNew from "../components/Splash/StoreConfirmationModalNew";
import { useParams } from "react-router";
import { clearOrderFormSession } from "@aan/order-form";
import { unwrapResult } from "@reduxjs/toolkit";
import SplashNewDesktop from "./SplashNewDesktop";
import SplashNewMobile from "./SplashNewMobile";
import WhatIsRentToOwnModal from "../components/Splash/WhatIsRentToOwnModal";

function SplashNew() {
    const width = useWidth();
    const [isStoreConfirmationModalOpen, setIsStoreConfirmationModalOpen] = useState(false);
    const [isWhatIsRentToOwnModalOpen, setIsWhatIsRentToOwnModalOpen] = useState(false);
    const dispatch = useDispatch();
    const pathname = useSelector((state) => state.router.location.pathname);
    const store = useSelector((state) => state.store);
    const applySession = useSelector((state) => state.session.sessionId);
    const orderFormSession = useSelector(
    (state) => state.orderForm.session.sessionId
    );
    let { storeNumber } = useParams();
    let canGetDecision = (!store?.storeNumber || store.defaultOrderFlowType === 1) ? true : false;

    useEffect(() => {
    if (applySession || orderFormSession) {
        dispatch(clearSession());
        dispatch(clearOrderFormSession());
    }

    var urlParams = new URLSearchParams(window.location.search);
        if (urlParams) {
            if (
            urlParams.has("web") ||
            (urlParams.has("utm_source") && urlParams.get("utm_source") === "web")
            ) {
            dispatch(setChannelType(3));
            }
            if (
            urlParams.has("utm_medium") &&
            urlParams.get("utm_medium") === "email"
            ) {
            dispatch(setChannelType(4));
            }
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        async function loadStoreAsync() {
        dispatch(loadStore(storeNumber))
            .then(unwrapResult)
            .catch(() => {
            dispatch(
                showAlert(
                "error",
                "We're having trouble finding your store. Please search again."
                )
            );
            dispatch(push("/storeSelector"));
            });
        }
        if (storeNumber) {
        storeNumber = storeNumber.replace(/o/gi, "0"); // eslint-disable-line
        loadStoreAsync();
        }
    }, [storeNumber]); // eslint-disable-line

    async function handleApplyNowClick(event) {
        if (store.storeNumber) {
            setIsStoreConfirmationModalOpen(true);
        } else {
            goToStoreSelector(event);
        }
    };
    
    async function handleOnSubmit(event) {
        event.preventDefault();
        await dispatch(startNewSession(pathname));
        await dispatch(loadStore(store.storeNumber));
        dispatch(push("/form/personal"));
    }

    async function goToStoreSelector(event) {
        event.preventDefault();
        await dispatch(startNewSession(pathname));
        dispatch(push("/storeSelector"));
    }

  return (
    <Box style={{ width: "100%", height: "100%" }}>
        {width !== "xs" && <SplashNewDesktop 
            store={store} 
            handleApplyNowClick={handleApplyNowClick}
            canGetDecision={canGetDecision}
            setIsWhatIsRentToOwnModalOpen={setIsWhatIsRentToOwnModalOpen}
        />}
        {width === "xs" && <SplashNewMobile 
            store={store}
            handleApplyNowClick={handleApplyNowClick}
            canGetDecision={canGetDecision}
            setIsWhatIsRentToOwnModalOpen={setIsWhatIsRentToOwnModalOpen}
        />}
        <StoreConfirmationModalNew
          open={isStoreConfirmationModalOpen}
          onClose={() => setIsStoreConfirmationModalOpen(false)}
          goToStoreSelector={goToStoreSelector}
          onSubmit={handleOnSubmit}
          addressLine1={store.addressLine1}
          city={store.city}
          state={store.state}
          postalCode={store.postalCode}
          storeName={store.storeName}
          store={store.storeNumber}
          phoneNumber={store.phoneNumber}
        />
        <WhatIsRentToOwnModal
          isOpen={isWhatIsRentToOwnModalOpen}
          onCloseClick={() => setIsWhatIsRentToOwnModalOpen(false)}
        />
    </Box>
  );
}

export default SplashNew;
