import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTranslate } from "@aan/localization-react";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import TermsOfServiceModal from "./TermsOfServiceModal";

function PrivacyAndTosFooter() {
  const { translate } = useTranslate();
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [isTermsServiceOpen, setIsTermsServiceOpen] = useState(false);
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      style={{ paddingBottom: 20, maxWidth: 500, margin: "0 auto" }}
    >
      <PrivacyPolicyModal
        isOpen={isPrivacyOpen}
        onCloseClick={() => setIsPrivacyOpen(false)}
      />
      <TermsOfServiceModal
        isOpen={isTermsServiceOpen}
        onCloseClick={() => setIsTermsServiceOpen(false)}
      />
      <Grid item>
        <Button
          className="full-width privacy-button button"
          style={{
            color: "white",
            fontFamily: "Montserrat, Roboto, sans-serif",
          }}
          onClick={() => setIsPrivacyOpen(true)}
        >
          <b>{translate("privacy_policy")}</b>
        </Button>
      </Grid>
      <Grid item>
        <Button
          className="full-width privacy-button button"
          style={{
            color: "white",
            fontFamily: "Montserrat, Roboto, sans-serif",
          }}
          onClick={() => setIsTermsServiceOpen(true)}
        >
          <b>{translate("terms_of_service")}</b>
        </Button>
      </Grid>
    </Grid>
  );
}

export default PrivacyAndTosFooter;
