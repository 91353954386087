import { 
  Grid, 
  Typography, 
} from "@material-ui/core";
import React, { } from "react";
import SweepstakesBanner from "../images/sweepstakes_basic_banner.png";
import useWidth from "../hooks/useWidth";

//TODO: form group
//Test navigating

function Sweepstakes() {
  const width = useWidth();

  let isLargeScreen = (width === "md" || width === "lg" || width === "xl");

  return (
    <div style={{ position: "relative", backgroundColor: "#e3f5ce", minHeight: "calc(100vh * 1.4)", overflow: "hidden" }}>
      <img
        style={{  opacity: "0.2",
        mixBlendMode: "multiply", 
        position: "absolute",
        width: "100%",
        height: "auto",
        marginTop: isLargeScreen ? 220 : 180,
        zIndex: 2 }}
        alt=""
      />
      <Grid   
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      style={{ position: "relative", zIndex: 3 }}>
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" style={{  width: "100vw" }}>
            <Grid item style={{ marginTop: 30 }}>
              {isLargeScreen && <img
                src={SweepstakesBanner}
                style={{ width: 500 }}
                alt="Aaron's Pot O' Gold Sweepstakes"
              />}
              {!isLargeScreen && <img
                src={SweepstakesBanner}
                style={{ width: 300 }}
                alt="Aaron's Pot O' Gold Sweepstakes"
              />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 20}}>
              <Typography style={{ fontSize: 20, textAlign: "center", fontWeight: "bold", marginBottom: 24, fontFamily: "Montserrat, Roboto, sans-serif"}} variant="h1">This Pot O' Gold Sweepstakes has officially ended.</Typography>
              <Typography style={{ fontSize: 20, textAlign: "center", fontWeight: "bold", marginBottom: 8, fontFamily: "Montserrat, Roboto, sans-serif"}} variant="h1">No more entries can be accepted.</Typography>     
        </Grid>
    </Grid>
    </div>
  );
}

export default Sweepstakes;