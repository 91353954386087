import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialState from "./initialState";
import ApplyApi from "../api/applyApi";
import trackProgress from "../utilities/trackProgress";
import { clearSession, setOrderFlowType } from "../reducers/sessionSlice";
import { initializeSessionAsync } from "@aan/order-form";

export const loadStore = createAsyncThunk(
  "loadStore",
  async (storeNumber, { getState, dispatch }) => {
    let progress = trackProgress();
    try {
      const { orderFlowType, sessionId, channelTypeId } = getState().session;
      let store = await ApplyApi.getStore(storeNumber);
      progress.done();
      await dispatch(
        initializeSessionAsync(
          storeNumber,
          sessionId,
          channelTypeId,
          store,
          () => dispatch(clearSession())
        )
      );
      if (store.defaultOrderFlowType && window.dataLayer) {
        if (store.defaultOrderFlowType === 1) {
          window.dataLayer.push({ event: "shortOrderForm" });
        } else {
          window.dataLayer.push({ event: "longOrderForm" });
        }
      }
      dispatch(setOrderFlowType(store.defaultOrderFlowType));
      if (sessionId) {
        ApplyApi.insertNavigation(
          sessionId,
          "/loadStore",
          { store },
          orderFlowType,
          storeNumber,
          channelTypeId
        );
      }
      return store;
    } catch (error) {
      progress.done();
      throw new Error("Server Error returned.");
    }
  }
);

export const storeSlice = createSlice({
  name: "store",
  initialState: initialState.store,
  extraReducers: {
    [loadStore.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

export default storeSlice.reducer;
