import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import React, { useEffect } from "react";
import { push } from "connected-react-router";
import ContinueButton from "../components/ContinueButton";
import ContactCopy from "../components/ContactCopy";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import AaronsLogo from "../components/Splash/AaronsLogo";
import FurnitureIcons from "../components/Splash/FurnitureIcons";
import SplashTitle from "../components/Splash/SplashTitle";
import ApplyMainCopy from "../components/Splash/ApplyMainCopy";
import PrivacyAndTosFooter from "../components/PrivacyAndTosFooter";
import useWidth from "../hooks/useWidth";
import { useTranslate } from "@aan/localization-react";
import LanguagePicker from "../components/LanguagePicker";
import ApplyApi from '../api/applyApi';

const useStyles = makeStyles(theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing(3)
  }),
  mainContainer: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.main
  },
  buttonContainer: {
    width: 443,
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  centeredContent: {
    margin: "0 auto",
    paddingTop: 40,
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 20,
      width: "90%"
    }
  },
  getStartedButton: {
    width: "100%",
    height: 60,
    backgroundColor: "white",
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "Montserrat, Roboto, sans-serif",
    "&:hover": {
      background: "white"
    }
  },
  backToLocationButton: {
    width: "100%",
    height: 60,
    color: "white",
    fontSize: 16,
    fontWeight: "bold"
  },
  infoContainer: {
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 40
    }
  },
  legalTextContainer: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 25,
    paddingBottom: 15
  },
  legalText: {
    color: "white"
  }
}));

function ApplySplash() {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const store = useSelector(state => state.store);  
  const { orderFlowType, sessionId, channelTypeId } = useSelector(state => state.session);
  const classes = useStyles();
  const width = useWidth();

  useEffect(() => {    
    if (!store.storeNumber || !sessionId) {
      dispatch(push("/"));
    }
  }, [dispatch, store]); // eslint-disable-line

  function handleOnSubmit(event) {
    event.preventDefault();
    ApplyApi.insertNavigation(sessionId, "/apply", null, orderFlowType, store.storeNumber, channelTypeId);
    dispatch(push("/form/personal"));
    return false;
  }

  function goToStoreSelector(event) {
    event.preventDefault();
    dispatch(push("/storeSelector"));
    return false;
  }

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.centeredContent}>
        <AaronsLogo width={width} />
        <LanguagePicker style={{ position: "absolute", right: 10, top: 10, color: "white" }}/>
        <FurnitureIcons width={width} />
        {width !== "xs" && width !== "sm" && (
          <Grid item container direction="row" justify="space-between" className={classes.infoContainer}>
            <Grid item>
              <SplashTitle
                width={width}
                page="applySplash"
                store={store.storeNumber}
                canGetDecision={orderFlowType === 1 ? true : false}
              />
              <ApplyMainCopy
                width={width}
                page="applySplash"
                store={store.storeNumber}
                canGetDecision={orderFlowType === 1 ? true : false}
              />
            </Grid>
            <Grid item>
              {store && store.storeNumber && (
                <ContactCopy
                  addressLine1={store.addressLine1}
                  city={store.city}
                  state={store.state}
                  postalCode={store.postalCode}
                  phoneNumber={store.phoneNumber}
                  storeName={store.storeName}
                  width={width}
                  orderFlowType={orderFlowType}
                />
              )}
            </Grid>
          </Grid>
        )}
        {(width === "xs" || width === "sm") && (
          <Grid item container direction="column" justify="space-between" className={classes.infoContainer}>
            <SplashTitle
              width={width}
              page="applySplash"
              store={store.storeNumber}
              canGetDecision={orderFlowType === 1 ? true : false}
            />
            <ApplyMainCopy
              width={width}
              page="applySplash"
              store={store.storeNumber}
              canGetDecision={orderFlowType === 1 ? true : false}
            />
            <Grid item>
              {store && store.storeNumber && (
                <ContactCopy
                  addressLine1={store.addressLine1}
                  city={store.city}
                  state={store.state}
                  postalCode={store.postalCode}
                  phoneNumber={store.phoneNumber}
                  storeName={store.storeName}
                  width={width}
                  orderFlowType={orderFlowType}
                />
              )}
            </Grid>
          </Grid>
        )}
        <Grid item className={classes.buttonContainer} align='center'>
          <ContinueButton className={classes.getStartedButton} onClick={handleOnSubmit} id="getstarted-button">
            {translate("apply_applybtn")}<ForwardArrowIcon style={{ marginLeft: 2, fontSize: 34 }}></ForwardArrowIcon>
          </ContinueButton>
          <Button className={classes.backToLocationButton} onClick={goToStoreSelector}>
            {translate("apply_changebtn")}
          </Button>
        </Grid>
        {orderFlowType === 1 && (
          <Grid className={classes.legalTextContainer}>
            <Typography className={classes.legalText}>{translate("apply_legal_1")}</Typography>
          </Grid>
        )}
        {orderFlowType !== 1 && (
          <Grid className={classes.legalTextContainer}>
            <Typography className={classes.legalText}>{translate("apply_legal_2")}</Typography>
          </Grid>
        )}
        <PrivacyAndTosFooter />
      </Grid>
    </Grid>
  );
}

export default ApplySplash;
