import { configureStore } from '@reduxjs/toolkit';
import { reduxTimeout } from "redux-timeout";
import { routerMiddleware } from "connected-react-router";
import rootReducer, { history } from "../reducers";

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: rootReducer,
    //Redux toolKit getDefaultMiddleWare automatically adds [thunk, immutableStateInvariant, serializableStateInvariant] to dev and [thunk] to production
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(routerMiddleware(history), reduxTimeout()),
    devTools: process.env.NODE_ENV !== 'production', 
    preloadedState
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(store.reducer))
  }

  return store
}