import React, { } from "react";
import useWidth from "../hooks/useWidth";
import AaronsLogo from "../components/Splash/AaronsLogo";
import LanguagePicker from "../components/LanguagePicker";
import SplashTitle from "../components/Splash/SplashTitle";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import PrivacyAndTosFooter from "../components/PrivacyAndTosFooter";
import SplashBullets from "../components/Splash/SplashBullets";
import ForwardArrowIcon from "@material-ui/icons/ArrowForward";
import { useTranslate } from "@aan/localization-react";
import Blades from "../images/blades.png";
import SlashBkgnd from "../images/slash-bkgrnd.png";
import GradientHorizontal from "../images/Gradient2.svg"
import PatternedBackground from "../images/icon-background-pattern-large.png"
import ConfettiBackgroundBlue from "../images/confeti-background-pattern.png"
import ConfettiBackground from "../images/Confetti_patterns_opacity.png"
import { AARONS_BLUE } from "../constants/colors";

const textBoldStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "left"
};

const textBlueStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    textAlign: "left",
    fontWeight: "bold"
};

const textBlueTitleStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    fontWeight: "bold",
    fontSize: 20
};

const textBlueAltStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 20
};

const numListTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: AARONS_BLUE,
    fontWeight: 600,
    fontSize: 18,
    marginTop: 6
};

const numFontStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontSize: 22,
    color: "white",
    fontWeight: "bold",
    marginTop: -5,
    marginLeft: 5
};

const numListItemStyle = {
    marginTop: 10,
    marginLeft: 10
};

const circledNumberStyle = {
    border: `thin ${AARONS_BLUE} solid`, backgroundColor: AARONS_BLUE, padding: 10, borderRadius: 50, width: 20, height: 20
};

const bulletSectionHeaderTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: 28,
    marginBottom: 10
}

const buttonTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    color: AARONS_BLUE,
    fontSize: 20,
    marginRight: 10
}

const buttonTextAltStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontWeight: "bold",
    color: "white",
    fontSize: 18,
    marginRight: 10
}

const legalTextStyle = {
    fontFamily: "Montserrat, Roboto, sans-serif",
    color: "white",
}

const contentWidth = 800;

function SplashNewDesktop({store, handleApplyNowClick, canGetDecision, setIsWhatIsRentToOwnModalOpen}) {
  const width = useWidth();
  const { translate } = useTranslate();

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <Box style={{ backgroundImage: `url(${PatternedBackground})`, backgroundAttachment: "fixed" }}>
        <Box style={{ padding: 30 }}>
            <Box style={{ marginLeft: 15 }}>
                <AaronsLogo width={width} />
            </Box>
            <LanguagePicker
                style={{ position: "absolute", right: 30, top: 30, color: "white" }}
            />
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item style={{ width: contentWidth }}>
                    <Box style={{ backgroundImage: `url(${GradientHorizontal})`, backgroundRepeat: "repeat-y" }}>
                        <Box style={{ padding: 30 }}>
                            <SplashTitle
                                width={width}
                                page="mainSplash"
                                store={store.storeNumber}
                                canGetDecision={canGetDecision}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item style={{ width: contentWidth }}>
                    <Box style={{ marginTop: 30 }}>
                    {canGetDecision && <Typography style={{
                        fontFamily: "Montserrat, Roboto, sans-serif",
                        color: "white",
                        fontWeight: "bold",
                        marginBottom: 5,
                        fontSize: 28
                    }}>
                        {translate("apply_what_is_leasing")}
                    </Typography>}
                    {canGetDecision && <Typography style={{
                        fontFamily: "Montserrat, Roboto, sans-serif",
                        color: "white",
                        fontSize: 20
                    }}>
                        {translate("apply_leasing_desc")}
                    </Typography>}
                    {!canGetDecision && 
                        <Box>
                            <Typography style={{
                                fontFamily: "Montserrat, Roboto, sans-serif",
                                color: "white",
                                fontSize: 20,
                                marginBottom: 20
                            }}>
                                {translate("apply_para1")}
                            </Typography>
                            <Typography style={{
                                fontFamily: "Montserrat, Roboto, sans-serif",
                                color: "white",
                                fontSize: 20
                            }}>
                                {translate("apply_para2")}
                            </Typography>
                        </Box>
                    }
                    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ marginTop: 20 }}>
                        <Grid item>
                            <Box style={{ border: "thin #CCEEFB solid", padding: 20, backgroundImage: `url(${ConfettiBackgroundBlue})`, marginTop: 16, width: contentWidth}}>
                                <Typography style={textBlueTitleStyle}>{translate("apply_ready_to_apply")}</Typography>
                                <Typography style={textBlueAltStyle}>{translate("apply_just_follow")}</Typography>
                                <Grid container direction="row" style={numListItemStyle}>
                                    <Grid item xs={1}>
                                        <Box style={circledNumberStyle}>
                                            <Typography style={numFontStyle}>1</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography style={numListTextStyle}>{translate("apply_fill_out_app")}</Typography>
                                    </Grid>
                                </Grid>
                                {canGetDecision && <Grid container direction="row" style={numListItemStyle}>
                                    <Grid item xs={1}>
                                        <Box style={circledNumberStyle}>
                                            <Typography style={numFontStyle}>2</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography style={numListTextStyle}>{translate("apply_discover_approved")}</Typography>
                                    </Grid>
                                </Grid>}
                                <Grid container direction="row" style={numListItemStyle}>
                                    <Grid item xs={1}>
                                        <Box style={circledNumberStyle}>
                                            {!canGetDecision && <Typography style={numFontStyle}>2</Typography>}
                                            {canGetDecision && <Typography style={numFontStyle}>3</Typography>}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography style={numListTextStyle}>{translate("apply_shop_favorite")}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item style={{ marginTop: 30 }}>
                            <Button onClick={handleApplyNowClick} variant="contained" style={{ width: 350, height: 80, backgroundColor: "white" }}>
                                <Typography style={buttonTextStyle}>{translate("home_apply_now")}</Typography>
                                <ForwardArrowIcon style={{ fontSize: 34, color: AARONS_BLUE }} />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                </Grid>
            </Grid>     
        </Box>
      </Box>    
      <Box style={{ backgroundColor: "white", backgroundImage: `url(${ConfettiBackground})`, backgroundSize: "200%", padding: 30 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                <Typography style={bulletSectionHeaderTextStyle}>{translate("apply_flexible")}</Typography>
            </Grid>
            <Grid item style={{ width: (contentWidth + 50) }}>
                <SplashBullets/>
            </Grid>
        </Grid>
      </Box>
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: AARONS_BLUE, padding: 40, backgroundImage:  `url(${PatternedBackground})`, backgroundAttachment: "fixed" }}>
        <Grid container style={{ border: "thin #CCEEFB solid", padding: 30, paddingBottom: 50, backgroundImage: `url(${SlashBkgnd})`, backgroundSize: "cover", maxWidth: contentWidth }} direction="column" justifyContent="center" alignItems="center">
            <Grid item>
                <Grid container direction="row" justifyContent="center" style={{ marginLeft: 40 }}>
                    <Grid item xs={3}>
                        <img
                            src={Blades}
                            style={{ width: 160, marginRight: 200 }}
                            alt=""
                        />
                    </Grid>
                    <Grid item xs={8} style={{ marginTop: 40 }}>
                        <Typography style={textBlueStyle}>{translate("apply_affordable")}</Typography>
                        <Typography style={textBoldStyle}>{translate("apply_learn_more")}</Typography>
                        <Button onClick={() => setIsWhatIsRentToOwnModalOpen(true)} variant="contained" color="primary" style={{ width: 260, height: 50, marginTop: 30 }}>
                            <Typography style={buttonTextAltStyle}>
                                {translate("apply_see_how_works")}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: AARONS_BLUE, padding: 30 }}>
        {store?.storeNumber ? (
          <Grid item style={{ maxWidth: contentWidth }}>
            <Typography style={legalTextStyle}>
              {canGetDecision
                ? translate("home_footer_3")
                : translate("home_footer_2") + " - " + store?.phoneNumber}
            </Typography>
          </Grid>
        ) : (
          <Grid item style={{ maxWidth: contentWidth }}>
            <Typography style={legalTextStyle}>
              {translate("home_footer")}
            </Typography>
          </Grid>
        )}
        <Grid item style={{ marginTop: 20 }}>
            <PrivacyAndTosFooter />
        </Grid>
        </Grid>
    </Box>
  );
}

export default SplashNewDesktop;
