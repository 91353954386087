import Button from "@material-ui/core/Button";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslate } from "@aan/localization-react";
import CloseIcon from '@material-ui/icons/Close';

const boldText = {
  color: "#333333",
  fontWeight: 600,
  fontSize: 16,
  fontFamily: "Montserrat, Roboto, sans-serif"
};
const lightText = {
  color: "#333333",
  fontWeight: 300,
  fontSize: 16,
  fontFamily: "Montserrat, Roboto, sans-serif"
};

function StoreConfirmationModalNew({goToStoreSelector, onSubmit, onClose, open, storeName, store, addressLine1, city, state, postalCode, phoneNumber}) {  
  const { translate } = useTranslate();

    return (
      <Dialog open={open} PaperProps={{ "data-cy": "store-confirmation-dialog" }} fullWidth={true} maxWidth="xs">
        <Grid container direction="row" justifyContent="flex-end" style={{ backgroundColor: "#0069c8", height: 50 }}>
            <Grid item>
                <Button onClick={onClose}>
                    <CloseIcon style={{ color: "white", fontSize: 40 }}/>
                </Button>
            </Grid>
        </Grid>
        <div style={{ textAlign: "center", marginBottom: 22, paddingLeft: 4, paddingRight: 4 }}>
          <span>
            <i className="icon-store" style={{ fontSize: 90, marginBottom: -10, color: "#0069c8" }} />
          </span>
          <br />
          <span>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#404040",
                fontFamily: "Montserrat, Roboto, sans-serif",
                fontSize: 18,
                paddingLeft: 5,
                paddingRight: 5
              }}
            >
              {translate("please_confirm_store")}
            </Typography>
          </span>
        </div>
        <div style={{ margin: "0 auto", paddingLeft: 5, paddingRight: 5 }}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <i className="icon-map-marker-alt" style={{ fontSize: 28, color: "#0069c8" }} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" style={boldText} data-cy="your-store-location">
                {`${translate("sm_location", {store: storeName})} (${store})`}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {addressLine1}
              </Typography>
              <Typography variant="body1" style={lightText}>
                {city}, {state} {postalCode}
              </Typography>
            </Grid>
          </Grid>
          <a href={"tel:" + phoneNumber} style={{ textDecoration: "none", color: "#333333" }}>
            <Grid container direction="row" style={{ marginTop: 16 }}>
              <Grid item xs={2}>
                <i className="icon-phone-alt" style={{ fontSize: 28, color: "#0069c8" }} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="h6" style={boldText}>
                  {translate("sm_contact")}
                </Typography>
                <Typography variant="body1" style={lightText}>
                  {phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </a>
        </div>
        <DialogActions style={{margin: 24}}>
          <Button
            onClick={goToStoreSelector}
            color="primary"
            data-cy="store-confirm-no"
          >
            {translate("find_another_store")}
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            color="primary"
            data-cy="store-confirm-yes"
          >
            {translate("continue")}
          </Button>
        </DialogActions>
      </Dialog>
    );  
}

export default StoreConfirmationModalNew;
