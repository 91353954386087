import React from "react";
import { useSelector } from "react-redux";
import {AaronsLogo as BaseAaronsLogo} from "@aan/material-ui";

const aaronsLogoDesktop = {
  height: 60,
  width: "auto",
  marginBottom: 40
};

const aaronsLogoPhone = {
  height: 40,
  width: "auto",
  marginBottom: 20
};

const AaronsLogo = props => {
  const languages = useSelector(state => state.localize.languages);  
  return (
    <BaseAaronsLogo langCode={
      languages?.find((element) => element.code === "es")?.active === true
        ? "es"
        : "en"
    } color="white" root={props.width === "xs" || props.width === "sm" ? aaronsLogoPhone : aaronsLogoDesktop} />  
  );
};

export default AaronsLogo;
