import "whatwg-fetch";
import { ValidationError, ServerError, NotSuccessError } from "../errors";
import polly from "polly-js";
let config = global.config;

class ApplyApi {
  static async getStore(store) {
    return await ApplyApi.doFetch("GET", `/store/${store}`);
  }

  static async postSession(initialUrl, referrer, route) {
    return await ApplyApi.doFetch("POST", `/session`, {
      initialUrl,
      referrer,
      browser: navigator.userAgent,
      version: config.version,
      route,
    });
  }

  static async getInitialEcommFormData(ecommKey) {
    return await ApplyApi.doFetch(
      "GET",
      `/session/ecommFormData?key=${ecommKey}`
    );
  }

  static async postEcommFormData(
    sessionId,
    ecommKey,
    initialUrl,
    referrer,
    tcpa
  ) {
    return await ApplyApi.doFetch("POST", "/session/ecommFormData", {
      sessionId,
      ecommKey,
      initialUrl,
      referrer,
      tcpa,
      browser: navigator.userAgent,
      clientVersion: config.version,
    });
  }

  static async postCustomerTransfer(store, sessionId, newSessionId) {
    return await ApplyApi.doFetch("POST", "/session/customerTransfer", {
      store,
      sessionId,
      newSessionId,
    });
  }

  static async getDecision(sessionId) {
    return await ApplyApi.doFetch("POST", "/decision", { sessionId });
  }

  static async postPersonal(values) {
    return await ApplyApi.doFetch("POST", "/personal", {
      channelTypeId: 2,
      ...values,
    });
  }

  static async insertCustomer(
    sessionId,
    data,
    orderFlowType,
    storeNumber,
    channelTypeId,
    initialUrl,
    referrer
  ) {
    return await ApplyApi.doFetch("POST", "/customer", {
      sessionId: sessionId,
      customerData: data,
      orderFlowType,
      storeNumber,
      channelTypeId,
      initialUrl,
      browser: navigator.userAgent,
      version: config.version,
      referrer,
    });
  }

  static async postViewedEZPayDetailsTest(sessionId) {
    return await ApplyApi.doFetch(
      "POST",
      `/session/test/${sessionId}/viewedEZPayDetails`,
      {}
    );
  }

  static async postViewedLeasingPowerTest(sessionId) {
    return await ApplyApi.doFetch(
      "POST",
      `/session/test/${sessionId}/viewedLeasingPower`,
      {}
    );
  }

  static async insertNavigation(
    sessionId,
    route,
    formData,
    orderFlowType,
    storeNumber,
    channelTypeId
  ) {
    return await ApplyApi.doFetch("POST", "/navigation", {
      sessionId: sessionId,
      route: route,
      formData: formData,
      orderFlowType,
      storeNumber,
      channelTypeId,
    })
  }

  static async getAllStoresByPostalCode(postalCode) {
    return await ApplyApi.doFetch(
      "GET",
      `/Store/StoresByPostalCode?PostalCode=${postalCode}`
    );
  }

  static getLatLongForPostalCode(postalCode) {
    return new Promise((resolve, reject) => {
      fetch(
        `https://atlas.microsoft.com/search/address/structured/json?subscription-key=${config.gmapsApiKey}&api-version=1.0&postalCode=${postalCode}&countryCode=US`,
        {
          method: "GET",
          cache: "no-cache",
          headers: {
            Accept: "application/json",
          },
        }
      )
        .then((res) => {
          resolve(res.json());
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getLocation() {
    return new Promise((resolve, reject) => {
      fetch("https://lsplocationapi.aarons.com/LocationInformation", {
        method: "GET",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async postContest(formData) {
    return await ApplyApi.doFetch("POST", "/contest", {
      store: formData.storeNumber ? formData.storeNumber : "",
      firstName: formData.firstName,
      lastName: formData.lastName,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      city: formData.city,
      state: formData.state,
      zipCode: formData.zipCode,
      phoneNumber: formData.phoneNumber,
      emailAddress: formData.emailAddress,
      smsOptIn: formData.smsOptIn,
      emailOptIn: formData.emailOptIn,
      didAgreeToRules: formData.ageAndRulesAffirmation
    }).then((response) => {
      return "success";
    })
    .catch((error) => {
      return "error";
    });
  }

  static async doFetch(method, path, body, maxRetries = 0) {
    let headers = {
      Accept: "application/json",
      "client-app-version": config.version,
    };
    let options = {
      method,
      cache: "no-cache",
      headers,
    };
    if (method === "POST") {
      headers["Content-Type"] = "application/json";
      options.body = JSON.stringify(body);
    }

    if (maxRetries > 0) {
      return polly()
        .waitAndRetry(maxRetries)
        .executeForPromise(async () => {
          return await ApplyApi.fetchHelper(path, options);
        });
    } else {
      return await ApplyApi.fetchHelper(path, options);
    }
  }

  static async fetchHelper(path, opts) {
    const rsp = await fetch(config.serviceBase + path, opts);
    if (rsp.ok) {
      const contentType = rsp.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return rsp.json();
      }
      return Promise.resolve();
    } else {
      if (rsp.status === 400) {
        let valErrorBody = await rsp.json();
        throw new ValidationError(`Validation failed: ${path}`, valErrorBody);
      } else if (rsp.status === 500) {
        throw new ServerError("Server Error returned.");
      } else {
        throw new NotSuccessError(`Server returned ${rsp.status}`);
      }
    }
  }
}

export default ApplyApi;
