import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import initialState from './initialState';
import ApplyApi from '../api/applyApi';

export const getStoresByPostalCode = createAsyncThunk('getStoresByPostalCode', async (postalCode, { getState }) => {
  let data = { postalCode };
  let postalCodeData = {};
  let storeResults = {};

  let stores = await ApplyApi.getAllStoresByPostalCode(postalCode);
  if (stores.length > 0) {
    postalCodeData = await ApplyApi.getLatLongForPostalCode(postalCode);
    data.stores = stores.map(s => s.store);
  }
  if (postalCodeData.results && postalCodeData.results.length > 0 && postalCodeData.results[0].position) {
    storeResults = {position: postalCodeData.results[0].position, stores}
    data.position = postalCodeData.results[0].position;
  } else {
    storeResults = stores.length > 0 ? {position: null, stores} : {position: null, stores: []}
    data.position = null;
  }
  return storeResults;
});


export const storesSlice = createSlice({
  name: 'stores',
  initialState: initialState.stores,
  reducers: {
    SELECTED_STORES_SUCCESS: (state, action) => {
      state.lat = action.lat
      state.long = action.long
      state.stores = [...action.stores]
      state.selectedStore = action.stores[0]?.store
      state.initialRender = true
    },
    setSelectedStore: (state, action) => {
        return { ...state, selectedStore: action.payload, initialRender: false };
    }
  },
  extraReducers: {
    [getStoresByPostalCode.fulfilled]: (state, action) => {
      state.lat = action.payload.position.lat
      state.long = action.payload.position.lon
      state.stores = [...action.payload.stores]
      state.selectedStore = action.payload.stores[0]?.store
      state.initialRender = true
      state.loading = 'idle'
    },
    [getStoresByPostalCode.pending]: (state) => {
      state.loading = 'pending'
    },
    [getStoresByPostalCode.rejected]: (state, action) => {
      state.loading = 'idle'
      state.error = action.payload
    }
  }
})

export const { SELECTED_STORES_SUCCESS, setSelectedStore } = storesSlice.actions

export default storesSlice.reducer
