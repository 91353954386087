import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslate } from "@aan/localization-react";

const fontLarge = {
  color: "white",
  fontFamily: "Montserrat, Roboto, sans-serif",
  fontSize: 26
};

const fontSmall = {
  color: "white",
  fontFamily: "Montserrat, Roboto, sans-serif",
  fontSize: 16
};

const fontConstrictedWidthLarge = {
  color: "white",
  fontFamily: "Montserrat, Roboto, sans-serif",
  maxWidth: 500,
  fontSize: 26
};

const fontConstrictedWidthSmall = {
  color: "white",
  fontFamily: "Montserrat, Roboto, sans-serif",
  maxWidth: 500,
  fontSize: 16
};

const headerLine = {
  border: "thin #CCEEFB solid",
  backgroundColor: "#CCEEFB",
  marginTop: 40,
  marginBottom: 20,
  paddingLeft: 20,
  paddingRight: 20
};

const ApplyMainCopy = props => {
  const { translate } = useTranslate();

  return (
    <div style={{ marginBottom: 20 }}>
      {props.page === "mainSplash" && !props.store && (
        <Typography variant="body1" style={props.width === "xs" || props.width === "sm" ? fontSmall : fontLarge}>
          {translate("home_subheader")}
        </Typography>
      )}
      {props.store && !props.canGetDecision && (
        <div>
          <Typography
            variant="body1"
            style={props.width === "xs" || props.width === "sm" ? fontConstrictedWidthSmall : fontConstrictedWidthLarge}
          >
            {translate("home_sub_notsupported")}
          </Typography>
          <hr style={headerLine} />
          <Typography
            variant="body1"
            style={props.width === "xs" || props.width === "sm" ? fontConstrictedWidthSmall : fontConstrictedWidthLarge}
          >
            {translate("home_sub_approve")}
          </Typography>
        </div>
      )}
      {props.store && props.canGetDecision && (
        <Typography variant="body1" style={props.width === "xs" || props.width === "sm" ? fontSmall : fontLarge}>
          {translate("apply_discover")}
        </Typography>
      )}
    </div>
  );
};

export default ApplyMainCopy;
