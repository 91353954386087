import React from 'react';
import Button from '@material-ui/core/Button';

function ContinueButton(props) {  
  const {children, ...other} = props;

  return (
    <Button raised="true" variant="outlined" color="primary" {...other}>{children}</Button>
  );
}

export default ContinueButton;