const initialState = {
    store: {},
    stores: {
      lat: 0,
      long: 0,
      stores: []
    },
    customer: {
      isGettingDecision: false
    },
    ajaxCallsInProgress: 0
  };
  
  export default initialState;  