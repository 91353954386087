
import React from "react";
import { Typography } from "@material-ui/core";

const Heading1 = (props) => {
  return (
    <Typography 
    variant="h1"
    style={{
      fontFamily: "Montserrat, Roboto, sans-serif",
      color: "white",
      fontSize: 48
    }}
    >
      {props.text}
    </Typography>
  );
};

export default Heading1;

